import React from 'react';

import { NewsAdminItemUpdateDataComponent } from './news-admin-item-update-data.component';

import { useDispatch, useSelector } from 'react-redux';

import { newsAdminItemUpdateDataFormValidation } from './news-admin-item-update-data.validation';

import { convertNewsAdminItemUpdateDataFormData } from './news-admin-item-update-data.convert';

import {
  uploadNewsAdminItemUpdateDataForm,
  resetNewsAdminItemUpdateDataFormState,
} from './news-admin-item-update-data.action';

import {
  NEWS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME,
  NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME,
} from './news-admin-item-update-data.constant';

import { NAVIGATION_STORE_NAME } from '../../lib/common/navigation';
import {
  NEWS_ADMIN_ITEM_DATA_STORE_NAME,
  NEWS_ADMIN_ITEM_DATA_DATA_NAME,
} from '../news-admin-item-data/news-admin-item-data.constant';

import {
  getRequestData,
  getRequestErrorMessage,
  isRequestError,
  isRequestPending,
  isRequestSuccess,
} from '../../main/store/store.service';

export function NewsAdminItemUpdateDataContainer() {
  const dispatch = useDispatch();
  const { state, pageLoading, newsItemStore } = useSelector((state) => ({
    state: state[NEWS_ADMIN_ITEM_UPDATE_DATA_STORE_NAME],
    pageLoading: state[NAVIGATION_STORE_NAME].pageLoading,
    newsItemStore: state[NEWS_ADMIN_ITEM_DATA_STORE_NAME],
  }));

  React.useEffect(() => {
    dispatch(resetNewsAdminItemUpdateDataFormState());
  }, []);

  const newsAdminItemUpdateDataFormSendData = (values) => {
    const data = convertNewsAdminItemUpdateDataFormData(values);
    dispatch(uploadNewsAdminItemUpdateDataForm(data, newsItemStore.newsId));
  };

  const getInitialValue = () => {
    if (isRequestSuccess(newsItemStore.request)) {
      const data = getRequestData(newsItemStore.request);
      return {
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY]:
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.BODY],
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]:
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.CATEGORY],
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION]:
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.DESCRIPTION],
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG]:
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.LANG],
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]:
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.ORDER],
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PREVIEW]: [
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.PREVIEW],
        ],
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AUTHOR]: [
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.AUTHOR],
        ],

        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]:
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.STATUS],
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE]:
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.TITLE],
        [NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL]:
          data[NEWS_ADMIN_ITEM_DATA_DATA_NAME.URL],
      };
    }

    return {};
  };

  return (
    <NewsAdminItemUpdateDataComponent
      isPending={isRequestPending(state.form)}
      isError={isRequestError(state.form)}
      isSuccess={isRequestSuccess(state.form)}
      isDependentPending={isRequestPending(newsItemStore.request)}
      initialValue={getInitialValue()}
      validation={newsAdminItemUpdateDataFormValidation}
      onSubmitForm={newsAdminItemUpdateDataFormSendData}
      pageLoading={pageLoading}
      errorMessage={getRequestErrorMessage(state.form)}
    />
  );
}
