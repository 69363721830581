export const USER_ADMIN_ITEM_UPDATE_BONUS_STORE_NAME =
  "USER_ADMIN_ITEM_UPDATE_BONUS";

export const USER_ADMIN_ITEM_UPDATE_BONUS_API = {
  CHANGE_DATA: {
    ENDPOINT: (userId) => `/user/admin/user/${userId}`,
    TYPE: "PATCH",
  },
};

export const USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE = {
  FORM_PENDING: "USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE.FORM_PENDING",
  FORM_SUCCESS: "USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE.FORM_SUCCESS",
  FORM_ERROR: "USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE.FORM_ERROR",
  FORM_RESET: "USER_ADMIN_ITEM_UPDATE_BONUS_ACTION_TYPE.FORM_RESET",
};

export const USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME = {
  BONUS: "bonus",
  BONUS_HISTORY: "bonusHistory",
};
