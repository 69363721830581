import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import DoneIcon from "@mui/icons-material/Done";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import CircularProgress from "@mui/material/CircularProgress";
import { ListItemIcon, ListItemText } from "@mui/material";

import { TextComponent } from "../text";

export function NoticeItemDefaultComponent({
  text,
  createDate,
  view,
  loading,
  onDelete,
  disabled,
}) {
  const isSecondAction = onDelete && !view;

  return (
    <ListItem
      variant="list"
      sx={{ py: 2 }}
      secondaryAction={
        isSecondAction ? (
          <IconButton
            disabled={disabled}
            onClick={onDelete}
            edge="start"
            aria-label="delete"
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <DoneIcon
                sx={{
                  fontSize: "24px",
                  color: "#fff",
                  opacity: disabled ? 0.35 : 1,
                }}
              />
            )}
          </IconButton>
        ) : null
      }
    >
      {!view && (
        <ListItemIcon sx={{ minWidth: "4px", marginRight: 3 }}>
          <Brightness1Icon color="primary" sx={{ fontSize: "12px" }} />
        </ListItemIcon>
      )}

      <ListItemText
        primary={
          <Box sx={{ pr: isSecondAction ? 4 : 0 }}>
            <TextComponent variant="noticeData">{text}</TextComponent>
            <TextComponent sx={{ pt: 2 }} variant="noticeTime">
              {createDate}
            </TextComponent>
          </Box>
        }
      />
    </ListItem>
  );
}
