export const NEWS_ADMIN_ITEM_DATA_STORE_NAME = 'NEWS_ADMIN_ITEM_DATA';

export const NEWS_ADMIN_ITEM_DATA_API = {
  NEWS_ADMIN_ITEM_DATA: {
    ENDPOINT: (userId) => `/news/${userId}`,
    TYPE: 'GET',
  },
};

export const NEWS_ADMIN_ITEM_DATA_ACTION_TYPE = {
  REQUEST_PENDING: 'NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_PENDING',
  REQUEST_SUCCESS: 'NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_SUCCESS',
  REQUEST_ERROR: 'NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_ERROR',
  REQUEST_UPDATE_SUCCESS:
    'NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_SUCCESS',
  REQUEST_UPDATE_PENDING:
    'NEWS_ADMIN_ITEM_DATA_ACTION_TYPE.REQUEST_UPDATE_PENDING',
};

export const NEWS_ADMIN_ITEM_DATA_DATA_NAME = {
  ORDER: 'order',
  TITLE: 'title',
  DESCRIPTION: 'description',
  BODY: 'body',
  URL: 'url',
  PREVIEW: 'fileImage',
  CATEGORY: 'category',
  LANG: 'lang',
  ID: 'id',
  STATUS: 'status',
  CREATE_DATE: 'createDate',
  UPDATE_DATE: 'updateDate',
  AUTHOR: 'Author',
};
