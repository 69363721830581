import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Alert from "@mui/material/Alert";

import { Formik } from "formik";

import { LoaderBarComponent } from "../../lib/common/loader-bar";
import { TextComponent } from "../../lib/common/text";
import { UserAdminItemUpdateBonusFormComponent } from "./frame/user-admin-item-update-bonus-form.component";
import { UserAdminItemBonusHistoryComponent } from "./frame/admin-bonus-info.component";

import { SkeletonFormComponent } from "../../lib/common/skeleton-form/skeleton-form.component";

export function UserAdminItemUpdateBonusComponent(props) {
  const {
    initialValue,
    pageLoading,
    isPending,
    validation,
    onSubmitForm,
    isError,
    isSuccess,
    errorMessage,
    userEmail,
    isDependentPending,
    bonusHistory,
  } = props;

  return (
    <Box>
      <Paper sx={{ p: 6 }}>
        <Box>
          <Box sx={{ pb: 4 }}>
            <TextComponent
              variant="heading"
              gutterBottom
              component="div"
              tid="USER.ADMIN.ITEM_UPDATE_BONUS.HEADING"
            />
            <TextComponent
              variant="subtitle"
              component="div"
              tid="USER.ADMIN.ITEM_UPDATE_BONUS.SUBTITLE"
            />
          </Box>
          <Divider />
          {isDependentPending ? (
            <Box sx={{ py: 4 }}>
              <SkeletonFormComponent />
            </Box>
          ) : (
            <>
              <Formik
                enableReinitialize
                initialValues={initialValue}
                validate={validation}
                onSubmit={onSubmitForm}
              >
                {(props) => (
                  <UserAdminItemUpdateBonusFormComponent
                    {...props}
                    isPending={isPending}
                    isError={isError}
                    errorMessage={errorMessage}
                    pageLoading={pageLoading}
                    userEmail={userEmail}
                  />
                )}
              </Formik>
              <UserAdminItemBonusHistoryComponent data={bonusHistory} />
            </>
          )}

          {isError && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="error">
                <TextComponent tid={`ERROR.${errorMessage}`} />
              </Alert>
            </Box>
          )}
          {isSuccess && (
            <Box sx={{ pt: 4 }}>
              <Alert severity="success">
                <TextComponent tid="USER.ADMIN.ITEM_UPDATE_DATA.SUCCESS_MESSAGE" />
              </Alert>
            </Box>
          )}
          {isPending && <LoaderBarComponent />}
        </Box>
      </Paper>
    </Box>
  );
}
