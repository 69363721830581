export const PURCHASE_CUSTOMER_ITEM_CHAT_LIST_STORE_NAME =
  "PURCHASE_CUSTOMER_ITEM_CHAT_LIST";

export const PURCHASE_CUSTOMER_ITEM_CHAT_LIST_API = {
  PURCHASE_CUSTOMER_ITEM_CHAT_LIST: {
    ENDPOINT: (purchaseId) => `/chat-message/purchase/${purchaseId}/customer/`,
    TYPE: "GET",
  },
  PURCHASE_CUSTOMER_ITEM_CHAT_BOOSTER_NO_ANSWER: {
    ENDPOINT: (purchaseId) => `/notice/${purchaseId}/booster/no-answer`,
    TYPE: "POST",
  },
};

export const RELOAD_PURCHASE_CUSTOMER_ITEM_CHAT_LIST = 5000;
export const RELOAD_PURCHASE_CUSTOMER_DATA_CHAT_LIST = 5000;

export const PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE = {
  REQUEST_PENDING:
    "PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_PENDING",
  REQUEST_SUCCESS:
    "PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_SUCCESS",
  REQUEST_ERROR: "PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_ERROR",
  REQUEST_UPDATE_SUCCESS:
    "PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_SUCCESS",
  REQUEST_UPDATE_PENDING:
    "PURCHASE_CUSTOMER_ITEM_CHAT_LIST_ACTION_TYPE.REQUEST_UPDATE_PENDING",
};

export const PURCHASE_CUSTOMER_ITEM_CHAT_LIST_DATA_NAME = {
  ID: "id",
  VIEW: "view",
  DATA: "data",
  TYPE: "type",
  CREATE_DATE: "createDate",

  USER: "user",
  USER_LOGIN: "login",

  FILE: "file",
  FILE_NAME: "name",
  FILE_URL: "url",
};
