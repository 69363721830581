export const NEWS_STATUS_TYPE = {
  ACTIVE: 1,
  DISABLED: 2,
};

export const NEWS_CATEGORY_TYPE = {
  NEWS: 1,
  ESPORTS: 2,
  REVIEWS: 3,
  GUIDES: 4,

  GENERAL: 5,
  CS2: 6,
  LOL: 7,
  VALORANT: 8,
  DOTA: 9,
  DEADLOCK: 10,
  APEX: 11,
  FORTNITE: 12,
  OVERWATCH: 13,
};

export const NEWS_LANG_TYPE = {
  RU: 2,
  EN: 1,
};

export const NEWS_SORT_TYPE = {
  ORDER: 1,
  ID: 2,
};

export const NEWS_STATUS_VALUE = {
  1: 'ACTIVE',
  2: 'DISABLED',
};

export const NEWS_CATEGORY_VALUE = {
  1: 'NEWS',
  2: 'ESPORTS',
  3: 'REVIEWS',
  4: 'GUIDES',

  5: 'GENERAL',
  6: 'CS2',
  7: 'LOL',
  8: 'VALORANT',
  9: 'DOTA',
  10: 'DEADLOCK',
  11: 'APEX',
  12: 'FORTNITE',
  13: 'OVERWATCH',
};

export const NEWS_LANG_VALUE = {
  2: 'RU',
  1: 'EN',
};

export const NEWS_SORT_VALUE = {
  1: 'ORDER',
  2: 'ID',
};

export const NEWS_STATUS_OPTION = [
  { id: 1, value: 1, tid: 'NEWS.DATA.STATUS.ACTIVE' },
  { id: 2, value: 2, tid: 'NEWS.DATA.STATUS.DISABLED' },
];

export const NEWS_CATEGORY_OPTION = [
  // { id: 1, value: 1, tid: 'NEWS.DATA.CATEGORY.NEWS' },
  // { id: 2, value: 2, tid: 'NEWS.DATA.CATEGORY.ESPORTS' },
  // { id: 3, value: 3, tid: 'NEWS.DATA.CATEGORY.REVIEWS' },
  // { id: 4, value: 4, tid: 'NEWS.DATA.CATEGORY.GUIDES' },

  { id: 5, value: 5, tid: 'NEWS.DATA.CATEGORY.GENERAL' },
  { id: 6, value: 6, tid: 'NEWS.DATA.CATEGORY.CS2' },
  { id: 7, value: 7, tid: 'NEWS.DATA.CATEGORY.LOL' },
  { id: 8, value: 8, tid: 'NEWS.DATA.CATEGORY.VALORANT' },
  { id: 9, value: 9, tid: 'NEWS.DATA.CATEGORY.DOTA' },
  { id: 10, value: 10, tid: 'NEWS.DATA.CATEGORY.DEADLOCK' },
  { id: 11, value: 11, tid: 'NEWS.DATA.CATEGORY.APEX' },
  { id: 12, value: 12, tid: 'NEWS.DATA.CATEGORY.FORTNITE' },
  { id: 13, value: 13, tid: 'NEWS.DATA.CATEGORY.OVERWATCH' },
];

export const NEWS_LANG_OPTION = [
  { id: 2, value: 2, tid: 'NEWS.DATA.LANG.RU' },
  { id: 1, value: 1, tid: 'NEWS.DATA.LANG.EN' },
];

export const NEWS_SORT_OPTION = [
  { id: 1, value: 1, tid: 'NEWS.DATA.SORT.ORDER' },
  { id: 2, value: 2, tid: 'NEWS.DATA.SORT.ID' },
];
