import Box from '@mui/material/Box';

import { FieldTextComponent } from '../../../lib/common/field-text/field-text.component';
import { FieldFileContainer } from '../../../lib/common/field-file/field-file.container';
import { FieldSelectComponent } from '../../../lib/common/field-select/field-select.component';

import { TextComponent } from '../../../lib/common/text';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME } from '../news-admin-item-update-data.constant';

import {
  NEWS_CATEGORY_OPTION,
  NEWS_LANG_OPTION,
  NEWS_STATUS_OPTION,
} from '../../../lib/common/news/news.type';

export const NewsAdminItemUpdateDataFormComponent = (props) => {
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    isValid,
    isSubmitting,

    pageLoading,
    isSuccess,
    isError,
    isPending,
    setFieldValue,
  } = props;

  const isFieldError = (name) => {
    return errors[name] && touched[name] && errors[name];
  };

  const getFieldError = (name) => isFieldError(name) && errors[name];

  const isSubmitDisabled = () => {
    if (isError) {
      return false;
    }

    if (isPending) {
      return true;
    }

    if (JSON.stringify(touched) === '{}') {
      return true;
    }

    if (!isValid || isSuccess || pageLoading) {
      return true;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ pt: 4 }}>
        <Grid spacing={3} container>
          <Grid item>
            <FieldTextComponent
              type="number"
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.ORDER" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER]}
              error={isFieldError(NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER)}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.ORDER,
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.TITLE" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE]}
              error={isFieldError(NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE)}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.TITLE,
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.DESCRIPTION" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION]}
              error={isFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION,
              )}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.DESCRIPTION,
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.AUTHOR" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AUTHOR}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AUTHOR]}
              error={isFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AUTHOR,
              )}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.AUTHOR,
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.URL" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL]}
              error={isFieldError(NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL)}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.URL,
              )}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={NEWS_CATEGORY_OPTION}
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.CATEGORY" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY]}
              error={isFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY,
              )}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.CATEGORY,
              )}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={NEWS_LANG_OPTION}
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.LANG" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG]}
              error={isFieldError(NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG)}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.LANG,
              )}
            />
          </Grid>
          <Grid item>
            <FieldSelectComponent
              option={NEWS_STATUS_OPTION}
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.STATUS" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS]}
              error={isFieldError(NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS)}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.STATUS,
              )}
            />
          </Grid>
          <Grid item>
            <FieldTextComponent
              type="text"
              multiline
              rows={4}
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.BODY" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY]}
              error={isFieldError(NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY)}
              fullWidth
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.BODY,
              )}
            />
          </Grid>
          <Grid item>
            <FieldFileContainer
              label={
                <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.FILE" />
              }
              name={NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PREVIEW}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PREVIEW]}
              error={isFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PREVIEW,
              )}
              errorText={getFieldError(
                NEWS_ADMIN_ITEM_UPDATE_DATA_DATA_NAME.PREVIEW,
              )}
            />
          </Grid>
          <Grid item>
            <Button type="sumbit" disabled={isSubmitDisabled()} fullWidth>
              <TextComponent tid="NEWS.ADMIN.ITEM_UPDATE_DATA.FORM.SUBMIT" />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
