import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { FieldTextComponent } from "../../../lib/common/field-text/field-text.component";
import { TextComponent } from "../../../lib/common/text";
import { USER_ADMIN_ITEM_UPDATE_BONUS_DATA_NAME } from "../user-admin-item-update-bonus.constant";
import styled from "styled-components";

export const UserAdminItemBonusHistoryComponent = ({ data }) => {
  return (
    <Wrapper>
      <Divider />
      <List>
        {data.bonusHistory.map((e, index) => (
          <ListElem key={index}>
            {data.bonusHistory.length < 1 && (
              <TextComponent tid="USER.ADMIN.ITEM_UPDATE_BONUS.FORM.BONUS_HISTORY_EMPTY" />
            )}
            {e.purchaseId === null ? (
              <TextComponent
                tid="USER.ADMIN.ITEM_UPDATE_BONUS.FORM.BONUS_HISTORY_USER"
                tvalue={{
                  date: e.date,
                  bonus: e.bonus,
                  userId: e.userId,
                }}
              />
            ) : (
              <TextComponent
                tid="USER.ADMIN.ITEM_UPDATE_BONUS.FORM.BONUS_HISTORY_PURCHASE"
                tvalue={{
                  date: e.date,
                  bonus: e.bonus,
                  purchaseId: e.purchaseId,
                }}
              />
            )}
          </ListElem>
        ))}
      </List>
    </Wrapper>
  );
};

const ListElem = styled.li`
  list-style: circle;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-top: 16px;
`;

const Wrapper = styled.div`
  margin-top: 16px;
`;
