// СТРУКТУРА КАРТИНКИ - <type>__<rank>

// КОНФИГУРАТОР ЕЛО
// В rank вписать константу ранга от FACEIT_BOOST_TYPE_LEVEL__<rank>
const FACEIT_ELO_RANGE = [
  { from: 0, to: 500, rank: "ELO_1" },
  { from: 501, to: 750, rank: "ELO_2" },
  { from: 751, to: 900, rank: "ELO_3" },
  { from: 901, to: 1050, rank: "ELO_4" },
  { from: 1051, to: 1200, rank: "ELO_5" },
  { from: 1201, to: 1350, rank: "ELO_6" },
  { from: 1351, to: 1530, rank: "ELO_7" },
  { from: 1531, to: 1750, rank: "ELO_8" },
  { from: 1751, to: 2000, rank: "ELO_9" },
  { from: 2001, to: 5000, rank: "ELO_10" },
];

// КОНФИГУРАТОР ЕЛО
// В rank вписать константу ранга от ESEA_BOOST_TYPE_RANK__<rank>
const ESEA_MMR_RANGE = [
  { from: 0, to: 499, rank: "D_MINUS" },
  { from: 500, to: 999, rank: "D" },
  { from: 1000, to: 1499, rank: "D_PLUS" },
  { from: 1500, to: 1999, rank: "C_MINUS" },
  { from: 2000, to: 2499, rank: "C" },
  { from: 2500, to: 2999, rank: "C_PLUS" },
  { from: 3000, to: 3499, rank: "B_MINUS" },
  { from: 3500, to: 3999, rank: "B" },
  { from: 4000, to: 4499, rank: "B_PLUS" },
  { from: 4500, to: 4999, rank: "A_MINUS" },
  { from: 5000, to: 5499, rank: "A" },
  { from: 5500, to: 6249, rank: "A_PLUS" },
  { from: 6250, to: 7499, rank: "G" },
  { from: 7500, to: 9000, rank: "S" },
];

const APEX_MMR_RANGE = [
  { from: 1, to: 999, rank: "APEX_BRONZE_4" },
  { from: 1000, to: 1999, rank: "APEX_BRONZE_3" },
  { from: 2000, to: 2999, rank: "APEX_BRONZE_2" },
  { from: 3000, to: 3999, rank: "APEX_BRONZE_1" },
  { from: 4000, to: 4999, rank: "APEX_SILVER_4" },
  { from: 5000, to: 5999, rank: "APEX_SILVER_3" },
  { from: 6000, to: 6999, rank: "APEX_SILVER_2" },
  { from: 7000, to: 7999, rank: "APEX_SILVER_1" },
  { from: 8000, to: 8999, rank: "APEX_GOLD_4" },
  { from: 9000, to: 9999, rank: "APEX_GOLD_3" },
  { from: 10000, to: 10999, rank: "APEX_GOLD_2" },
  { from: 11000, to: 11999, rank: "APEX_GOLD_1" },
  { from: 12000, to: 12999, rank: "APEX_PLATINUM_4" },
  { from: 13000, to: 13999, rank: "APEX_PLATINUM_3" },
  { from: 14000, to: 14999, rank: "APEX_PLATINUM_2" },
  { from: 15000, to: 15999, rank: "APEX_PLATINUM_1" },
  { from: 16000, to: 16999, rank: "APEX_DIAMOND_4" },
  { from: 17000, to: 17999, rank: "APEX_DIAMOND_3" },
  { from: 18000, to: 18999, rank: "APEX_DIAMOND_2" },
  { from: 19000, to: 19999, rank: "APEX_DIAMOND_1" },
  { from: 20000, to: 20999, rank: "APEX_MASTER_4" },
  { from: 21000, to: 21999, rank: "APEX_MASTER_3" },
  { from: 22000, to: 22999, rank: "APEX_MASTER_2" },
  { from: 23000, to: 23999, rank: "APEX_MASTER_1" },
  { from: 24000, rank: "APEX_PREDATOR" },
];

const DOTA_MMR_RANGE = [
  { from: 1, to: 153, rank: "DOTA_HERALD_1" },
  { from: 154, to: 307, rank: "DOTA_HERALD_2" },
  { from: 308, to: 461, rank: "DOTA_HERALD_3" },
  { from: 462, to: 615, rank: "DOTA_HERALD_4" },
  { from: 616, to: 769, rank: "DOTA_HERALD_5" },
  { from: 770, to: 923, rank: "DOTA_GUARDIAN_1" },
  { from: 924, to: 1077, rank: "DOTA_GUARDIAN_2" },
  { from: 1078, to: 1231, rank: "DOTA_GUARDIAN_3" },
  { from: 1232, to: 1385, rank: "DOTA_GUARDIAN_4" },
  { from: 1386, to: 1539, rank: "DOTA_GUARDIAN_5" },
  { from: 1540, to: 1693, rank: "DOTA_CRUSADER_1" },
  { from: 1694, to: 1847, rank: "DOTA_CRUSADER_2" },
  { from: 1848, to: 2001, rank: "DOTA_CRUSADER_3" },
  { from: 2002, to: 2155, rank: "DOTA_CRUSADER_4" },
  { from: 2156, to: 2309, rank: "DOTA_CRUSADER_5" },
  { from: 2310, to: 2463, rank: "DOTA_ARCHOR_1" },
  { from: 2464, to: 2617, rank: "DOTA_ARCHOR_2" },
  { from: 2618, to: 2771, rank: "DOTA_ARCHOR_3" },
  { from: 2772, to: 2925, rank: "DOTA_ARCHOR_4" },
  { from: 2926, to: 3079, rank: "DOTA_ARCHOR_5" },
  { from: 3080, to: 3233, rank: "DOTA_LEGEND_1" },
  { from: 3234, to: 3387, rank: "DOTA_LEGEND_2" },
  { from: 3388, to: 3541, rank: "DOTA_LEGEND_3" },
  { from: 3542, to: 3695, rank: "DOTA_LEGEND_4" },
  { from: 3696, to: 3849, rank: "DOTA_LEGEND_5" },
  { from: 3850, to: 4003, rank: "DOTA_ANCIENT_1" },
  { from: 4004, to: 4157, rank: "DOTA_ANCIENT_2" },
  { from: 4158, to: 4311, rank: "DOTA_ANCIENT_3" },
  { from: 4312, to: 4465, rank: "DOTA_ANCIENT_4" },
  { from: 4466, to: 4619, rank: "DOTA_ANCIENT_5" },
  { from: 4620, to: 4819, rank: "DOTA_DIVINE_1" },
  { from: 4820, to: 5019, rank: "DOTA_DIVINE_2" },
  { from: 5020, to: 5219, rank: "DOTA_DIVINE_3" },
  { from: 5220, to: 5419, rank: "DOTA_DIVINE_4" },
  { from: 5420, to: 5999, rank: "DOTA_DIVINE_5" },
  { from: 6000, rank: "DOTA_IMMORTAL" },
];

const DOTA_BEHAVIOR_MMR_RANGE = [
  { from: 1, to: 153, rank: "DOTA_HERALD_1" },
  { from: 154, to: 307, rank: "DOTA_HERALD_2" },
  { from: 308, to: 461, rank: "DOTA_HERALD_3" },
  { from: 462, to: 615, rank: "DOTA_HERALD_4" },
  { from: 616, to: 769, rank: "DOTA_HERALD_5" },
  { from: 770, to: 923, rank: "DOTA_GUARDIAN_1" },
  { from: 924, to: 1077, rank: "DOTA_GUARDIAN_2" },
  { from: 1078, to: 1231, rank: "DOTA_GUARDIAN_3" },
  { from: 1232, to: 1385, rank: "DOTA_GUARDIAN_4" },
  { from: 1386, to: 1539, rank: "DOTA_GUARDIAN_5" },
  { from: 1540, to: 1693, rank: "DOTA_CRUSADER_1" },
  { from: 1694, to: 1847, rank: "DOTA_CRUSADER_2" },
  { from: 1848, to: 2001, rank: "DOTA_CRUSADER_3" },
  { from: 2002, to: 2155, rank: "DOTA_CRUSADER_4" },
  { from: 2156, to: 2309, rank: "DOTA_CRUSADER_5" },
  { from: 2310, to: 2463, rank: "DOTA_ARCHOR_1" },
  { from: 2464, to: 2617, rank: "DOTA_ARCHOR_2" },
  { from: 2618, to: 2771, rank: "DOTA_ARCHOR_3" },
  { from: 2772, to: 2925, rank: "DOTA_ARCHOR_4" },
  { from: 2926, to: 3079, rank: "DOTA_ARCHOR_5" },
  { from: 3080, to: 3233, rank: "DOTA_LEGEND_1" },
  { from: 3234, to: 3387, rank: "DOTA_LEGEND_2" },
  { from: 3388, to: 3541, rank: "DOTA_LEGEND_3" },
  { from: 3542, to: 3695, rank: "DOTA_LEGEND_4" },
  { from: 3696, to: 3849, rank: "DOTA_LEGEND_5" },
  { from: 3850, to: 4003, rank: "DOTA_ANCIENT_1" },
  { from: 4004, to: 4157, rank: "DOTA_ANCIENT_2" },
  { from: 4158, to: 4311, rank: "DOTA_ANCIENT_3" },
  { from: 4312, to: 4465, rank: "DOTA_ANCIENT_4" },
  { from: 4466, to: 4619, rank: "DOTA_ANCIENT_5" },
  { from: 4620, to: 4819, rank: "DOTA_DIVINE_1" },
  { from: 4820, to: 5019, rank: "DOTA_DIVINE_2" },
  { from: 5020, to: 5219, rank: "DOTA_DIVINE_3" },
  { from: 5220, to: 5419, rank: "DOTA_DIVINE_4" },
  { from: 5420, to: 5999, rank: "DOTA_DIVINE_5" },
  { from: 6000, rank: "DOTA_IMMORTAL" },
];

const DEADLOCK_MMR_RANGE = [
  { from: 1, to: 153, rank: "DEADLOCK_HERALD_1" },
  { from: 154, to: 307, rank: "DEADLOCK_HERALD_2" },
  { from: 308, to: 461, rank: "DEADLOCK_HERALD_3" },
  { from: 462, to: 615, rank: "DEADLOCK_HERALD_4" },
  { from: 616, to: 769, rank: "DEADLOCK_HERALD_5" },
  { from: 770, to: 923, rank: "DEADLOCK_GUARDIAN_1" },
  { from: 924, to: 1077, rank: "DEADLOCK_GUARDIAN_2" },
  { from: 1078, to: 1231, rank: "DEADLOCK_GUARDIAN_3" },
  { from: 1232, to: 1385, rank: "DEADLOCK_GUARDIAN_4" },
  { from: 1386, to: 1539, rank: "DEADLOCK_GUARDIAN_5" },
  { from: 1540, to: 1693, rank: "DEADLOCK_CRUSADER_1" },
  { from: 1694, to: 1847, rank: "DEADLOCK_CRUSADER_2" },
  { from: 1848, to: 2001, rank: "DEADLOCK_CRUSADER_3" },
  { from: 2002, to: 2155, rank: "DEADLOCK_CRUSADER_4" },
  { from: 2156, to: 2309, rank: "DEADLOCK_CRUSADER_5" },
  { from: 2310, to: 2463, rank: "DEADLOCK_ARCHOR_1" },
  { from: 2464, to: 2617, rank: "DEADLOCK_ARCHOR_2" },
  { from: 2618, to: 2771, rank: "DEADLOCK_ARCHOR_3" },
  { from: 2772, to: 2925, rank: "DEADLOCK_ARCHOR_4" },
  { from: 2926, to: 3079, rank: "DEADLOCK_ARCHOR_5" },
  { from: 3080, to: 3233, rank: "DEADLOCK_LEGEND_1" },
  { from: 3234, to: 3387, rank: "DEADLOCK_LEGEND_2" },
  { from: 3388, to: 3541, rank: "DEADLOCK_LEGEND_3" },
  { from: 3542, to: 3695, rank: "DEADLOCK_LEGEND_4" },
  { from: 3696, to: 3849, rank: "DEADLOCK_LEGEND_5" },
  { from: 3850, to: 4003, rank: "DEADLOCK_ANCIENT_1" },
  { from: 4004, to: 4157, rank: "DEADLOCK_ANCIENT_2" },
  { from: 4158, to: 4311, rank: "DEADLOCK_ANCIENT_3" },
  { from: 4312, to: 4465, rank: "DEADLOCK_ANCIENT_4" },
  { from: 4466, to: 4619, rank: "DEADLOCK_ANCIENT_5" },
  { from: 4620, to: 4819, rank: "DEADLOCK_DIVINE_1" },
  { from: 4820, to: 5019, rank: "DEADLOCK_DIVINE_2" },
  { from: 5020, to: 5219, rank: "DEADLOCK_DIVINE_3" },
  { from: 5220, to: 5419, rank: "DEADLOCK_DIVINE_4" },
  { from: 5420, to: 5999, rank: "DEADLOCK_DIVINE_5" },
  { from: 6000, to: 13000, rank: "DEADLOCK_IMMORTAL" },
];

const DEADLOCK_BEHAVIOR_MMR_RANGE = [
  { from: 1, to: 153, rank: "DEADLOCK_HERALD_1" },
  { from: 154, to: 307, rank: "DEADLOCK_HERALD_2" },
  { from: 308, to: 461, rank: "DEADLOCK_HERALD_3" },
  { from: 462, to: 615, rank: "DEADLOCK_HERALD_4" },
  { from: 616, to: 769, rank: "DEADLOCK_HERALD_5" },
  { from: 770, to: 923, rank: "DEADLOCK_GUARDIAN_1" },
  { from: 924, to: 1077, rank: "DEADLOCK_GUARDIAN_2" },
  { from: 1078, to: 1231, rank: "DEADLOCK_GUARDIAN_3" },
  { from: 1232, to: 1385, rank: "DEADLOCK_GUARDIAN_4" },
  { from: 1386, to: 1539, rank: "DEADLOCK_GUARDIAN_5" },
  { from: 1540, to: 1693, rank: "DEADLOCK_CRUSADER_1" },
  { from: 1694, to: 1847, rank: "DEADLOCK_CRUSADER_2" },
  { from: 1848, to: 2001, rank: "DEADLOCK_CRUSADER_3" },
  { from: 2002, to: 2155, rank: "DEADLOCK_CRUSADER_4" },
  { from: 2156, to: 2309, rank: "DEADLOCK_CRUSADER_5" },
  { from: 2310, to: 2463, rank: "DEADLOCK_ARCHOR_1" },
  { from: 2464, to: 2617, rank: "DEADLOCK_ARCHOR_2" },
  { from: 2618, to: 2771, rank: "DEADLOCK_ARCHOR_3" },
  { from: 2772, to: 2925, rank: "DEADLOCK_ARCHOR_4" },
  { from: 2926, to: 3079, rank: "DEADLOCK_ARCHOR_5" },
  { from: 3080, to: 3233, rank: "DEADLOCK_LEGEND_1" },
  { from: 3234, to: 3387, rank: "DEADLOCK_LEGEND_2" },
  { from: 3388, to: 3541, rank: "DEADLOCK_LEGEND_3" },
  { from: 3542, to: 3695, rank: "DEADLOCK_LEGEND_4" },
  { from: 3696, to: 3849, rank: "DEADLOCK_LEGEND_5" },
  { from: 3850, to: 4003, rank: "DEADLOCK_ANCIENT_1" },
  { from: 4004, to: 4157, rank: "DEADLOCK_ANCIENT_2" },
  { from: 4158, to: 4311, rank: "DEADLOCK_ANCIENT_3" },
  { from: 4312, to: 4465, rank: "DEADLOCK_ANCIENT_4" },
  { from: 4466, to: 4619, rank: "DEADLOCK_ANCIENT_5" },
  { from: 4620, to: 4819, rank: "DEADLOCK_DIVINE_1" },
  { from: 4820, to: 5019, rank: "DEADLOCK_DIVINE_2" },
  { from: 5020, to: 5219, rank: "DEADLOCK_DIVINE_3" },
  { from: 5220, to: 5419, rank: "DEADLOCK_DIVINE_4" },
  { from: 5420, to: 5999, rank: "DEADLOCK_DIVINE_5" },
  { from: 6000, to: 13000, rank: "DEADLOCK_IMMORTAL" },
];

const TFT_HYPER_MMR_RANGE = [
  { from: 1, to: 1400, rank: "TFT_HYPER_GRAY" },
  { from: 1401, to: 2600, rank: "TFT_HYPER_GREEN" },
  { from: 2601, to: 3400, rank: "TFT_HYPER_BLUE" },
  { from: 3401, to: 4200, rank: "TFT_HYPER_PURPLE" },
  { from: 4201, rank: "TFT_HYPER" },
];

const TFT_DOUBLE_MMR_RANGE = [
  { from: 1, to: 1400, rank: "TFT_DOUDLE_GRAY" },
  { from: 1401, to: 2600, rank: "TFT_DOUDLE_GREEN" },
  { from: 2601, to: 3400, rank: "TFT_DOUDLE_BLUE" },
  { from: 3401, to: 4200, rank: "TFT_DOUDLE_PURPLE" },
  { from: 4201, rank: "TFT_DOUDLE" },
];

const PREMIER_MMR_RANGE = [
  { from: 0, to: 4999, rank: "PREMIER_GREY" },
  { from: 5000, to: 9999, rank: "PREMIER_CYAN" },
  { from: 10000, to: 14999, rank: "PREMIER_BLUE" },
  { from: 15000, to: 19999, rank: "PREMIER_PURPLE" },
  { from: 20000, to: 24999, rank: "PREMIER_PINK" },
  { from: 25000, to: 29999, rank: "PREMIER_RED" },
  { from: 30000, to: 40000, rank: "PREMIER_GOLD" },
];

const WILD_RIFT_MMR_RANGE = [
  { from: 1, to: 799, rank: "WILD_RIFT_WARRIOR" },
  { from: 800, to: 999, rank: "WILD_RIFT_COMMANER" },
  { from: 1000, to: 1199, rank: "WILD_RIFT_COMMANER" },
  { from: 1200, to: 1399, rank: "WILD_RIFT_COMMANER" },
  { from: 1400, to: 1599, rank: "WILD_RIFT_COMMANER" },
  { from: 1600, to: 1799, rank: "WILD_RIFT_GUARDIAN" },
  { from: 1800, to: 1999, rank: "WILD_RIFT_GUARDIAN" },
  { from: 2000, to: 2199, rank: "WILD_RIFT_GUARDIAN" },
  { from: 2200, to: 2399, rank: "WILD_RIFT_GUARDIAN" },
  { from: 2400, to: 2599, rank: "WILD_RIFT_VANQUISHER" },
  { from: 2600, to: 2799, rank: "WILD_RIFT_VANQUISHER" },
  { from: 2800, to: 2999, rank: "WILD_RIFT_VANQUISHER" },
  { from: 3000, to: 3199, rank: "WILD_RIFT_VANQUISHER" },
  { from: 3200, to: 3399, rank: "WILD_RIFT_IMMORTAL" },
  { from: 3400, to: 3599, rank: "WILD_RIFT_IMMORTAL" },
  { from: 3600, to: 3799, rank: "WILD_RIFT_IMMORTAL" },
  { from: 3800, to: 3999, rank: "WILD_RIFT_IMMORTAL" },
];

const findRangePoint = (value, factor) => {
  for (let i = 0; i < factor.length; i++) {
    if (factor[i].from >= value || value <= factor[i].to) {
      return { ...factor[i], index: i };
    }
  }
};

export const getBoostRankImageUrl = (rankType, url = "") => {
  const [type, rank] = rankType.split("$");

  if (type === "APEX_BOOST_TYPE_NORMAL_KILL") {
    return `${url}/static/boost/WIN_${rank}.png`;
  }

  if (type === "APEX_BOOST_TYPE_RANK") {
    const rankPoint = findRangePoint(rank, APEX_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (type === "DOTA_BOOST_TYPE_MMR") {
    const rankPoint = findRangePoint(rank, DOTA_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (type === "DEADLOCK_BOOST_TYPE_MMR") {
    const rankPoint = findRangePoint(rank, DEADLOCK_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (type === "DOTA_BOOST_TYPE_NORMAL_BEHAVIOUR") {
    const rankPoint = findRangePoint(rank, DOTA_BEHAVIOR_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (type === "DEADLOCK_BOOST_TYPE_NORMAL_BEHAVIOUR") {
    const rankPoint = findRangePoint(rank, DEADLOCK_BEHAVIOR_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (type === "WILD_RIFT_BOOST_TYPE_LEGENDARY_RANK") {
    const rankPoint = findRangePoint(rank, WILD_RIFT_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (type === "TFT_BOOST_TYPE_HYPER_ROLL") {
    const rankPoint = findRangePoint(rank, TFT_HYPER_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (type === "TFT_BOOST_TYPE_DOUBLE_UP") {
    const rankPoint = findRangePoint(rank, TFT_DOUBLE_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (rankType === "WINGAME_BOOST_TYPE_PLACEMENT$LEM") {
    return `${url}/static/boost/WINGAME_BOOST_TYPE_PLACEMENT__DESIRED.png`;
  }
  if (rankType === "WINGAME_BOOST_TYPE_PLACEMENT$UNRANKED") {
    return `${url}/static/boost/WINGAME_BOOST_TYPE_PLACEMENT__CURRENT.png`;
  }

  if (rankType === "FACEIT_BOOST_TYPE_PLACEMENT$1000") {
    return `${url}/static/boost/FACEIT_BOOST_TYPE_PLACEMENT__CURRENT.png`;
  }

  if (rankType === "FACEIT_BOOST_TYPE_PLACEMENT$1401") {
    return `${url}/static/boost/FACEIT_BOOST_TYPE_PLACEMENT__DESIRED.png`;
  }

  if (rankType === "ESEA_BOOST_TYPE_PLACEMENT$D_MINUS") {
    return `${url}/static/boost/ESEA_BOOST_TYPE_PLACEMENT__CURRENT.png`;
  }

  if (rankType === "ESEA_BOOST_TYPE_PLACEMENT$A_MINUS") {
    return `${url}/static/boost/ESEA_BOOST_TYPE_PLACEMENT__DESIRED.png`;
  }

  if (type === "FACEIT_BOOST_TYPE_ELO") {
    const rankPoint = findRangePoint(rank, FACEIT_ELO_RANGE);
    return `${url}/static/boost/FACEIT_BOOST_TYPE_LEVEL__${rankPoint.rank}.png`;
  }

  if (type === "CSGO_BOOST_TYPE_PREMIER") {
    const rankPoint = findRangePoint(rank, PREMIER_MMR_RANGE);
    return `${url}/static/boost/${rankPoint.rank}.png`;
  }

  if (rankType === "CSGO_BOOST_TYPE_PREMIER_PLACEMENT$13000") {
    return `${url}/static/boost/PREMIER_BLUE.png`;
  }

  if (type === "ESEA_BOOST_TYPE_MMR") {
    const rankPoint = findRangePoint(rank, ESEA_MMR_RANGE);
    return `${url}/static/boost/ESEA_BOOST_TYPE_RANK__${rankPoint.rank}.png`;
  }
  switch (type) {
    case "ESPORTAL_BOOST_TYPE_PLACEMENT":
      if (rank === "UNRANKED") {
        return `${url}/static/boost/ESPORTAL_DEFAULT.png`;
      } else if (rank.slice(0, 3) === "WIN") {
        return `${url}/static/boost/${rank}__SQUARE.png`;
      } else {
        return `${url}/static/boost/${rank}.png`;
      }

    case "CSGO_BOOST_TYPE_PREMIER_PLACEMENT":
      if (rank === "UNRANKED") {
        return `${url}/static/boost/PREMIER_UNRANKED.png`;
      } else if (rank.slice(0, 3) === "WIN") {
        return `${url}/static/boost/${rank}__SQUARE.png`;
      } else {
        return `${url}/static/boost/${rank}.png`;
      }
    case "FACEIT_BOOST_TYPE_PLACEMENT":
      if (rank === "UNRANKED") {
        return `${url}/static/boost/FACEIT_DEFAULT.png`;
      } else if (rank.slice(0, 3) === "WIN") {
        return `${url}/static/boost/${rank}.png`;
      } else {
        return `${url}/static/boost/FACEIT_BOOST_TYPE_LEVEL__${rank}.png`;
      }
    case "ESEA_BOOST_TYPE_PLACEMENT":
      if (rank === "UNRANKED") {
        return `${url}/static/boost/ESEA_DEFAULT.png`;
      } else if (rank.slice(0, 3) === "WIN") {
        return `${url}/static/boost/${rank}.png`;
      } else {
        return `${url}/static/boost/ESEA_BOOST_TYPE_RANK__${rank}.png`;
      }
    case "WINGAME_BOOST_TYPE_PLACEMENT":
      if (rank === "UNRANKED") {
        return `${url}/static/boost/CSGO_DEFAULT__WINGAME.png`;
      } else if (rank.slice(0, 3) === "WIN") {
        return `${url}/static/boost/${rank}__SQUARE.png`;
      } else {
        return `${url}/static/boost/WINGAME_BOOST_TYPE_RANK__${rank}.png`;
      }
    case "CSGO_BOOST_TYPE_PLACEMENT":
      if (rank === "UNRANKED") {
        return `${url}/static/boost/CSGO_DEFAULT__RANK.png`;
      } else if (rank.slice(0, 3) === "WIN") {
        return `${url}/static/boost/${rank}__SQUARE.png`;
      } else {
        return `${url}/static/boost/CSGO_BOOST_TYPE_RANK__${rank}.png`;
      }
  }
  switch (type) {
    case "CSGO_BOOST_TYPE_RANK":
    case "CSGO_BOOST_TYPE_WIN":
    case "FACEIT_BOOST_TYPE_WIN":
    case "FACEIT_BOOST_TYPE_LEVEL":
    case "ESEA_BOOST_TYPE_WIN":
    case "WINGAME_BOOST_TYPE_RANK":
    case "WINGAME_BOOST_TYPE_WIN":
      return `${url}/static/boost/${type}__${rank}.png`;
  }

  return `${url}/static/boost/${rank}.png`;
};
